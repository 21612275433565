.footer {
    width: 100%;
    height: 100px;
    /* border-top: 1px solid #eaeaea; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer img {
    margin-left: 0.5rem;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 1em;
}

.top-background-container {
    background-image: url(/src/assets/svg/hero-section.svg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}
.join-us-background-container {
    background-image: url(/join-us-background.png);
}
.static-pages-background-top-container {
    background-image: url(/backgroundTop.png);
    background-size: cover;
}
.static-pages-background-bottom-container {
    background-image: url(/backgroundBottom.png);
    background-repeat: round;
    background-size: cover;
}
.static-pages-background-bottom1-container {
    background-image: url(/backgroundBottom1.png);
    background-size: cover;
    background-repeat: round;
}
.static-pages-background-our-team-container {
    background-image: url(/ourTeamBackground.png);
    background-size: cover;
    background-repeat: round;
}
.footer {
    background-image: url(/rectangle5.png);
    height: 150px;
    background: #f2f2f2;
}
.blog-container {
    background-image: url(/blog-section.svg);
}
