.klik-search-input {
    border-style: none;
    outline: none;
    width: 100%;
}

.klik-search-input::placeholder {
    color: #888888;
    font-weight: normal;
    font-size: 15.1859px;
    line-height: 24px;
    display: flex;
    align-items: center;

    /* Gray 3 */

    color: #828282;
}
